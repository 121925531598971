<template>
  <div class='user'>
    <div class='globle_border' v-loading="loading">
      <div class="search">
        <back />
        <div style="display: flex;">
          <el-select v-model="startStatus" placeholder="全部状态" @change="onSearch" clearable>
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
          <el-input v-model="productName" placeholder="请输入商品名称" suffix-icon="el-icon-search" @change="onSearch" clearable></el-input>
          <el-input v-model="barCode" placeholder="请输入商品条形码" suffix-icon="el-icon-search" @change="onSearch" clearable></el-input>
          <el-input v-model="supplierName" placeholder="请输入供应商名称" suffix-icon="el-icon-search" @change="onSearch" clearable></el-input>
        </div>
      </div>
      <div class="table_list globle_table">
        <el-table :data="tableData" style="width: 100%;" max-height="540">
          <el-table-column label="序号" show-overflow-tooltip width="80">
            <template slot-scope="scope">
              {{ scope.$index + 1 + (currentPage - 1) * pageSize }}
            </template>
          </el-table-column>
          <el-table-column prop="brand" label="品牌" show-overflow-tooltip>
          </el-table-column>
          <el-table-column prop="productName" label="商品名称" show-overflow-tooltip>
          </el-table-column>
          <el-table-column prop="categoryName" label="分类名称" show-overflow-tooltip>
          </el-table-column>
          <el-table-column prop="specs" label="规格">
          </el-table-column>
          <el-table-column prop="barCode" label="商品条形码" min-width="120" show-overflow-tooltip>
          </el-table-column>
          <el-table-column prop="name" label="中标有效期" min-width="200">
            <template slot-scope="scope">
              {{ scope.row.effectiveStartTime }}至{{ scope.row.effectiveEndTime }}
            </template>
          </el-table-column>
          <el-table-column prop="supplierName" label="中标商户" show-overflow-tooltip>
          </el-table-column>
          <el-table-column prop="biddingPrice" label="中标单价">
          </el-table-column>
          <el-table-column prop="contactPerson" label="中标联系人" min-width="120" show-overflow-tooltip> 
          </el-table-column>
          <el-table-column prop="status" label="中标预警">
            <template slot-scope="scope">
              <span :class="scope.row.warnStatus == '0' ? 'gray' : 'red'">
                {{ scope.row.warnStatus == 0 ? '否' : '是' }}
              </span>
            </template>
          </el-table-column>
          <el-table-column prop="status" label="处理状态">
            <template slot-scope="scope">
              <span :class="scope.row.processingStatus == '2' ? 'gray' : 'red'">
                {{ scope.row.processingStatus == 0 ? '' : scope.row.processingStatus == 1 ? '未处理' : '已处理' }}
              </span>
            </template>
          </el-table-column>
          <el-table-column label="操作" header-align="center" align="center" fixed="right" width="200">
            <template slot-scope="scope">
              <el-button v-if="scope.row.warnStatus != 1" class="btn" size="small" type="primary" plain @click="handleView(scope.row)">查看</el-button>
              <el-button v-if="scope.row.warnStatus == 1" class="btn" size="small" type="danger" plain @click="handleView(scope.row)">审核</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page.sync="currentPage" :page-size="pageSize" :page-sizes="pageSizeArr" layout="prev, pager, next, jumper, sizes, total" :total="totalItemsCount">
    </el-pagination>

    <!-- <el-dialog title="审核不通过" :visible.sync="submitVisible" :before-close="onCancel">
      <el-form ref="examineForm" class="examineForm" :rules="rules" :model="examineForm" label-position="left" label-width="130px">
        <el-form-item label="不通过原因" prop="loseContent">
          <el-input type="textarea" v-model="examineForm.loseContent" :autosize="{ minRows: 4, maxRows: 15 }"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button @click="onCancel">取 消</el-button>
        <el-button type="primary" @click="submitForm('examineForm')" :loading="submitLoding">确 定</el-button>
      </span>
    </el-dialog> -->
  </div>
</template>

<script>
export default {
  components: {},
  data () {
    return {
      content: "",
      loading: false,
      submitVisible: false,
      submitLoding: false,
      tenderStatus: "",
      barCode: "",
      productName: '',
      tendersName: "", //标书名称
      tendersNumber: "", //标号
      supplierName: "", //供应商
      tableData: [],
      currentPage: 1, // 当前页码
      pageSize: 10, // 每页显示的行数
      pageSizeArr: [10, 20, 50], // 每页显示的行数(可选)
      totalItemsCount: 0, // 总记录数（需要从后端获取）
      startStatus: "",
      options: [
        {
          value: 0,
          label: "否",
        },
        {
          value: 1,
          label: "是",
        },
      ],
      examineForm: {
        loseContent: "",
      },
      tenders: [
        {
          value: 1,
          label: "平台资格",
        },
        {
          value: 2,
          label: "独立标资格",
        },
      ],
      rules: {
        loseContent: [
          { required: true, message: "请输入不通过原因", trigger: "submit" },
        ],
      },
      tendersId: '',
      identification: ''
    };
  },

  created () {
    this.tendersId = this.$route.query.tendersId
    this.identification = this.$route.query.identification
    this.supplierName = localStorage.getItem("supplierName");
    this.tenders_list_new();
  },

  methods: {
    // 应标审核列表
    tenders_list_new () {
      this.loading = true;
      this.$axios
        .get(this.$api.tenders_list_new, {
          params: {
            page: this.currentPage,
            pageSize: this.pageSize,
            status: this.startStatus,
            supplierName: this.supplierName,
            tendersId: this.tendersId,
            productName: this.productName,
            barCode:this.barCode,
          },
        })
        .then((res) => {
          this.tableData = res.data.result.list;
          this.totalItemsCount = res.data.result.totalCount;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    onSearch () {

      if (this.supplierName == '') {
        localStorage.removeItem("supplierName");
      }
      this.currentPage = 1; // 搜索
      this.tableData = [];
      this.tenders_list_new();
    },

    // 查看
    handleView (row) {
      this.$router.push({
        path: "/biddingManagement/detail/winningBidDetails",
        query: { id: row.biddingProductId },
      });
    },

    // 不通过
    handleDelete (row) {
      this.submitVisible = true;
      this.id = row.applicationId;
    },
    // 提交表单
    submitForm (formName) {
      this.submitLoding = true;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$axios
            .post(this.$api.examine, {
              applicationId: this.id,
              loseContent: this.examineForm.loseContent,
              status: "2",
            })
            .then((res) => {
              this.$message.success(res.data.desc);
              this.tenders_list_new();
              this.submitLoding = false;
              this.onCancel();
            })
            .catch((err) => {
              this.$message.error(err.data.desc);
            });
        } else {
          this.submitLoding = false;
          this.$message.error("提交失败");
          return false;
        }
      });
    },
    // 通过
    handlepPass (row) {
      this.$confirm("确定该供应商通过?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        center: true,
      })
        .then(() => {
          this.$axios
            .post(this.$api.examine, {
              applicationId: row.applicationId,
              status: "1",
            })
            .then((res) => {
              if (res.data.code == 100) {
                this.$message.success(res.data.desc);
              }
              this.tenders_list_new();
            })
            .catch(() => { });
          // this.$message.success('确定该供应商通过')
        })
        .catch(() => { });
    },
    // 切换每页显示的条数
    handleSizeChange (e) {
      this.pageSize = e;
      this.tableData = [];
      this.onSearch();
    },
    // 换页
    handleCurrentChange () {
      this.page += 1;
      this.tableData = [];
      this.tenders_list_new();
    },
    // 关闭对话框
    onCancel () {
      this.submitVisible = false;
      this.examineForm = {};
    },
  },
};
</script>

<style scoped lang='scss'>
.user {
  .search {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 44px;
    margin-bottom: 20px;

    .el-input {
      width: 200px;
      margin-left: 20px;
    }

    .el-button {
      margin-left: 20px;
      
    }
  }

  // /* 设置表格单元格的最大宽度和省略显示 */
  // ::v-deep .el-table__row td .cell {
  //   max-width: 200px;
  //   /* 根据需要设置最大宽度 */
  //   overflow: hidden;
  //   text-overflow: ellipsis;
  //   white-space: nowrap;
  // }

  .table_list {
    width: 100%;
    box-sizing: border-box;
    overflow-x: auto;

    .desc {
      display: -webkit-box;
      word-break: break-all;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .red {
      color: #eb2500;
    }

    .green {
      color: #069bbc;
    }

    .gray {
      color: #68706e;
    }

    .logo {
      display: block;
      width: 58px;
      height: 58px;
      border-radius: 4px;
    }

    .busilicense {
      display: block;
      width: 80px;
      height: 58px;
      border-radius: 4px;
    }

    ::v-deep .el-table__fixed-header-wrapper thead tr th {
      color: rgba(3, 16, 14, 0.9);
      background-color: #f4f6f9;
    }
  }
}
</style>